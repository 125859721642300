<template>
  <div class="container app">
    <div class="row app-one">
      <div class="col-sm-4 side">
        <div class="side-one">
          <div class="row heading">
            <div class="col-sm-2 col-xs-3 heading-avatar">
              <div class="heading-avatar-icon">
                <img :src="authUser.foto" />
              </div>
            </div>
            <div class="col-sm-8 col-xs-7 heading-name">
              <a class="heading-name-meta">{{authUser.nome}}</a>
              <span class="heading-online">{{authUser.email}}</span>
            </div>
            <div class="col-sm-1 col-xs-1 dropdown heading-dot">
              <i
                  class="fa fa-ellipsis-v fa-2x pull-right dropdown-toggle"
                  style="float: right; margin-right: -10px;"
                  data-toggle="dropdown"
                  aria-haspopup="true" 
                  aria-expanded="false"
                  aria-hidden="true"
                ></i>
              <div class="dropdown-menu">
                <a class="dropdown-item" v-bind:class="{ disabled: authUser.perfil != 'A' }" href="/dashboard"><i class="fas fa-user-shield"></i> Administrador</a>
                <a class="dropdown-item" v-bind:class="{ disabled: authUser.perfil != 'A' }" href="#" v-on:click="setWebhook()"><i class="far fa-router"></i> Webhook</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#"  v-on:click="logOut()"><i class="fal fa-sign-out-alt"></i> Sair</a>
              </div>
            </div>
          </div>
          <div class="row searchBox">
            <div class="col-sm-12 searchBox-inner">
              <div class="form-group has-feedback">
                <input v-model="busca" type="text" class="form-control" placeholder="Buscar contato...">
              </div>
            </div>
          </div>
          <div class="row sideBar">
            <div class="row sideBar-body" v-on:click="selectContact(item.contato.id_contato)" v-bind:class="[{ active: $route.params.id_contato == item.contato.id_contato, opaco: item.contato.perfil_atendimento == 'R'}, 'perfil' + item.contato.perfil_atendimento]" v-for="item in contatos" :key="item.id">
              <div class="col-sm-2 col-xs-2 sideBar-avatar" rel="tooltip" :data-title="perfilAtendimentos.filter(perfil => perfil.id == item.contato.perfil_atendimento)[0].text" :data-content="(item.atendente) ? item.atendente.nome : 'Assistente Virtual'">
                <div class="avatar-icon">
                  <span class="contact-status" v-bind:class="{ away: item.contato.perfil_atendimento == 'R', online: item.contato.perfil_atendimento != 'R' }"></span>
                  <img v-bind:src="item.contato.foto" />
                </div>
              </div>
              <div class="col-sm-10 col-xs-10 sideBar-main">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <a class="heading-name-meta">{{item.contato.nome}} <span style="float:right;font-size:12px;font-weight:initial;width:initial;color: #93918f;">{{((item.mensagem) ? item.mensagem.data_mensagem : item.contato.data_cadastro) | formatDate('DD/MM/YYYY')}}</span></a>
                    <span class="heading-online" style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis; display: block;">{{(item.mensagem) ? item.mensagem.json.body : ''}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-8 conversation" v-if="$route.params.id_contato"> 
        <div class="row heading" >
          <div class="col-sm-1 col-xs-1 heading-avatar">
            <div class="heading-avatar-icon" v-on:click="getDadosContato($route.params.id_contato)">
              <img v-bind:src="contatoInfo.foto" />
            </div>
          </div>
          <div class="col-sm-9 col-xs-9 heading-name">
            <a v-on:click="getDadosContato($route.params.id_contato)" class="heading-name-meta">{{ contatoInfo.nome }}</a>
            <span class="heading-online">{{ $route.params.id_contato }}</span>
          </div>
          <div class="col-sm-1 col-xs-1 dropleft heading-dot">
            <i
                class="fa fa-ellipsis-v fa-2x pull-right dropdown-toggle"
                style="float: right; margin-right: -36px;z-index: 99999;"
                data-toggle="dropdown"
                aria-haspopup="true" 
                aria-expanded="false"
                aria-hidden="true"
              ></i>
            <div class="dropdown-menu">
              <a class="dropdown-item" href="#" v-on:click="getDadosContato($route.params.id_contato)"><i class="fal fa-user"></i> Dados do Contato</a>
              <a class="dropdown-item" href="#" v-on:click="getPesquisa($route.params.id_contato)"><i class="fal fa-question"></i> Enviar Pesquisa</a>
              <a class="dropdown-item" href="#" v-on:click="sendTemplate($route.params.id_contato)"><i class="far fa-address-book"></i> Enviar Template</a>
              <a class="dropdown-item" href="#" v-on:click="toogleAtendimento($route.params.id_contato)"><i class="fal fa-comments-alt"></i>{{(contatoInfo.perfil_atendimento == 'R') ? 'Iniciar' : 'Finalizar'}} Atendimento</a>
              <a class="dropdown-item" href="#" v-on:click="contactFoward($route.params.id_contato)"><i class="fal fa-share-square"></i>Encaminhar Contato</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#" v-on:click="clearMessages($route.params.id_contato)"><i class="far fa-trash-alt"></i> Apagar Conversa</a>
            </div>
          </div>
        </div>
        <div class="row message" v-chat-scroll="{smooth: true}">
          <div class="row message-previous">
            <div class="col-sm-12 previous">
            </div>
          </div>
          <div class="row message-body" v-for="item in chatMessages" :key="item.id">
            <div class="col-sm-12" v-bind:class="'message-main-' + item.direcao">
              <div class="" v-bind:class="item.direcao" v-if="item.tipo == 'text'">
                <div class="message-text" v-html="$options.filters.formatText(item.json)"></div>
                <span class="message-time pull-right"> {{item.data_mensagem | formatDate}} <i class="fal" v-if="item.direcao == 'sender'" v-bind:class="'icon-' + item.status_mensagem"></i></span>
              </div>
              <div class="" v-bind:class="item.direcao" v-if="item.tipo == 'template'">
                <div class="message-text">Olá, <br/> Como passou 24 horas do atendimento inicial, deseja continuar a conversa inicial?</div>
                <span class="message-time pull-right"> {{item.data_mensagem | formatDate}} <i class="fal" v-if="item.direcao == 'sender'" v-bind:class="'icon-' + item.status_mensagem"></i></span>
              </div>
              <div class="" v-bind:class="item.direcao" v-if="item.tipo == 'interactive'">
                <div class="message-text" v-html="$options.filters.formatInteractive(item.json)"></div>
                <span class="message-time pull-right"> {{item.data_mensagem | formatDate}} <i class="fal" v-if="item.direcao == 'sender'" v-bind:class="'icon-' + item.status_mensagem"></i></span>
              </div>
              <div class="" v-bind:class="item.direcao" v-else-if="item.tipo == 'image'">
                <div class="message-text"><img :src="$baseApi + '/api/mensagem/media/' + item.id_mensagem" style="width: 100%;"/></div>
                <span class="message-time pull-right"> {{item.data_mensagem | formatDate}} <i class="fal" v-if="item.direcao == 'sender'" v-bind:class="'icon-' + item.status_mensagem"></i></span>
              </div>
              <div class="" v-bind:class="item.direcao" v-else-if="item.tipo == 'voice'">
                <div class="message-text">
                   <audio  width="320" controls>
                    <source :src="$baseApi + '/api/mensagem/media/' + item.id_mensagem" type="audio/ogg">
                  </audio> 
                </div>
                <span class="message-time pull-right"> {{item.data_mensagem | formatDate}} <i class="fal" v-if="item.direcao == 'sender'" v-bind:class="'icon-' + item.status_mensagem"></i></span>
              </div>
              <div class="" v-bind:class="item.direcao" v-else-if="item.tipo == 'video'">
                <div class="message-text">
                  <video width="320" height="240" controls>
                    <source :src="$baseApi + '/api/mensagem/media/' + item.id_mensagem" type="video/mp4">
                  </video> 
                </div>
                <span class="message-time pull-right"> {{item.data_mensagem | formatDate}} <i class="fal" v-if="item.direcao == 'sender'" v-bind:class="'icon-' + item.status_mensagem"></i></span>
              </div>
              <div class="" v-bind:class="item.direcao" v-else-if="item.tipo == 'audio'">
                <div class="message-text">
                  <audio  width="320" controls>
                    <source :src="$baseApi + '/api/mensagem/media/' + item.id_mensagem" type="video/mp4">
                  </audio>
                </div>
                <span class="message-time pull-right"> {{item.data_mensagem | formatDate}} <i class="fal" v-if="item.direcao == 'sender'" v-bind:class="'icon-' + item.status_mensagem"></i></span>
              </div>
              <div class="" v-bind:class="item.direcao" v-else-if="item.tipo == 'document'">
                <div class="message-text">
                  <a :href="$baseApi + '/api/mensagem/media/' + item.id_mensagem" target="_blank" :download="item.json.filename" :title="item.json.caption">
                    <div class="info-box">                      
                      <span class="info-box-icon bg-aqua"><i class="fa fa-cloud-download"></i></span>
                      <div class="info-box-content">
                        <span class="info-box-text">{{item.json.filename}}</span>
                      </div>                      
                    </div>
                  </a>
                </div>
                <span class="message-time pull-right"> {{item.data_mensagem | formatDate}} <i class="fal" v-if="item.direcao == 'sender'" v-bind:class="'icon-' + item.status_mensagem"></i></span>
              </div>
            </div>
          </div>
          <div class="row message-previous">
            <div class="col-sm-12 previous">
            </div>
          </div>
        </div>
        <form v-on:submit.prevent="sendMessage">
          <div class="row reply">
            <div class="col-sm-1 col-xs-1 reply-emojis" v-on:click="openFile()">
              <i class="fas fa-paperclip fa-2x" aria-hidden="true"></i>
            </div>
            <div class="col-sm-10 col-xs-10 reply-main">
              <input type="text" :disabled="contatoInfo.perfil_atendimento == 'R'" class="form-control" rows="1" v-model="mensagem" placeholder="Digite sua mensagem..."/>
            </div>
            <div class="col-sm-1 col-xs-1 reply-send" v-on:click="sendMessage">
              <i class="fas fa-paper-plane fa-2x" aria-hidden="true"></i>
            </div>
          </div>
        </form>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="contactInfo" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{contatoInfo.nome}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p ><strong>Nome:</strong> {{contatoInfo.nome}}</p>
            <p><strong>Telefone:</strong> {{contatoInfo.id_contato | formatPhone}}</p>
            <p v-if="contatoInfo.cnpj"><strong>CNPJ:</strong> {{contatoInfo.cnpj}}</p>
            <p v-if="contatoInfo.empresa"><strong>Empresa:</strong> {{contatoInfo.empresa}}</p>
            <p v-if="contatoInfo.email"><strong>E-mail:</strong> {{contatoInfo.email}}</p>
            
            <p><strong>Data Cadastro:</strong> {{contatoInfo.data_cadastro | formatDate}}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default btn-sm" data-dismiss="modal">Fechar</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="contactFoward" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Encaminhar {{contatoEdit.nome}} para:</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <select class="form-control" id="exampleFormControlSelect1" v-model="contatoEdit.perfil_atendimento">
              <option v-for="option in perfilAtendimentos" :value="option.id" :key="option.id">
                {{ option.text }}
              </option>
            </select>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-sm" v-on:click="sendFoward()">Enviar</button>
            <button type="button" class="btn btn-default btn-sm" data-dismiss="modal">Fechar</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="fileModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Enviar Arquivos</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
             <input class="form-control" type="file" name="file" ref="file" accept="audio/aac,audio/mp4,audio/amr,audio/mpeg,audio/ogg; codecs=opus,video/ogg,image/jpeg,image/png,image/webp,video/mp4,video/3gpp,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/zip"/>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-sm" v-on:click="sendFile()">Enviar</button>
            <button type="button" class="btn btn-default btn-sm" data-dismiss="modal">Fechar</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import usuario from "@/controllers/usuario.controller";
import chat from "@/controllers/chat.controller";
import contato from "@/controllers/contato.controller";
import toastr from "@/utils/toastr";
import moment from "moment";

import Vue from 'vue';
import VueChatScroll from 'vue-chat-scroll';

Vue.use(VueChatScroll);

export default {
  name: "Login",
  data: function () {
    return {
      authUser: {},
      contatos: [],
      chatMessages: [],
      contatoInfo: {},
      contatoEdit: {},
      perfilAtendimentos: [],
      busca: "",
      mensagem: ""
    };
  },
  filters: {
    formatDate: function (value, formato) {
      if (!value) return '';

      if(!formato)
        formato = 'DD/MM/YYYY HH:mm';

      return moment( value ).calendar( null, {
          lastWeek: formato,
          lastDay:  formato,
          nextDay:  formato,
          nextWeek: formato,
          sameDay:  'HH:mm',
          sameElse: function () {
              return formato;
          }
      });
    },
    formatPhone: function (value) {
        if (!value) return '';

        return value.replace(/^(\d{2})(\d{2})(\d{5})(\d{4})/, '+$1 ($2) $3-$4');
    },
    formatText: function (objText) {
        if (!objText) return '';

        var transform = [
          {
            regexp: /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/ig,
            result: '<a href="{match}" target="_blank">{match}</a>'
          },
          {
            regexp: /_(.*?)_/ig,
            result: '<i>{match}</i>'
          },
          {
            regexp: /\*(.*?)\*/ig,
            result: '<b>{match}</b>'
          },
          {
            regexp: /~(.*?)~/ig,
            result: '<strike>{match}</strike>'
          }
        ];

        var textoFormatado = objText.body;

        $.each(transform, function(key, obj) {
          textoFormatado = textoFormatado.replace(obj.regexp, function(match, part) {
            return obj.result.replace(/\{match\}/g, part);
          });
        });

        return textoFormatado;
    },
    formatInteractive: function (objInteractive) {
        if (!objInteractive) return '';

        var transform = [
          {
            regexp: /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/ig,
            result: '<a href="{match}" target="_blank">{match}</a>'
          },
          {
            regexp: /_(.*?)_/ig,
            result: '<i>{match}</i>'
          },
          {
            regexp: /\*(.*?)\*/ig,
            result: '<b>{match}</b>'
          },
          {
            regexp: /~(.*?)~/ig,
            result: '<strike>{match}</strike>'
          }
        ];

        var textoFormatado = objInteractive.body.text;

        $.each(transform, function(key, obj) {
          textoFormatado = textoFormatado.replace(obj.regexp, function(match, part) {
            return obj.result.replace(/\{match\}/g, part);
          });
        });

        $.each(objInteractive.action.buttons, function(key, obj) {
          textoFormatado = textoFormatado +"\n<b>- " + obj.reply.title + "</b>";
        });        

        return textoFormatado;
    }
  },
  watch:{
    busca: function () {
      this.getContatos();
    }
  },
  methods: {
    canSend: function(){
      var receiverMsg = this.chatMessages.filter(function(message){
        return message.direcao == "receiver"
      });

      if(receiverMsg.length > 0)
      {
        let data_mensagem = moment( receiverMsg[receiverMsg.length - 1].data_mensagem ).add(24, 'hours');
        let currentTime = moment();        
        let cantExecute = currentTime.isSameOrAfter(data_mensagem);

        if(cantExecute){
          if(confirm("Mensagem fora da janela de suporte ao cliente(24 horas), você não está seguindo a política, portanto, a entrega desta mensagem não será realizada.\nDeseja enviar um feedback para o usuário?"))
          {
            this.sendTemplate(this.$route.params.id_contato);
          }
          return false;
        }
      }

      return true;
    },
    logOut: function() {
      this.$store.dispatch("logOut");
      location.href = '/';
    },
    selectContact: function (id_contato) {
      if(this.$route.params.id_contato != id_contato)
        this.$router.push({ name: 'chat', params: { id_contato: id_contato } });
      
      this.getMessages();
    },
    sendMessage: function () {
      if (!this.mensagem) return;
      if (!this.canSend()) return;

      const msg = this.mensagem;

      this.mensagem = "";

      chat.sendMessage({ id_contato: this.$route.params.id_contato, tipo: "text", conteudo: JSON.stringify({ body: msg}), direcao: "sender"})
        .then((response) => {
          if (!response.data)
            return;
         
          this.getMessages();
        })
        .catch((e) => {
          toastr.error(e);
        });
    },
    getMessages: function () {
      chat
        .getMessages({ id_contato: this.$route.params.id_contato })
        .then((response) => {
          this.chatMessages = response.data.result.mensagens;
          this.contatoInfo = response.data.result.contato || {};

          this.chatMessages.forEach(mensagem => {
            if(mensagem.status_mensagem != "read" && mensagem.direcao == "receiver")
              chat.readMessage({ id_mensagem: mensagem.id_mensagem })
          });

        })
        .catch(() => {
          this.logOut();
        });
    },
    getPerfilAtendimentos: function () {
      usuario
      .getPerfilAtendimentos()
      .then((response) => {
        if (!response.data)
          return;

        this.perfilAtendimentos = response.data;
      })
      .catch((e) => {
        toastr.error(e);
      });
    },
    getContatos: function () {
      contato
      .getContatos(this.busca)
      .then((response) => {
        if (!response.data)
          return;

        this.contatos = response.data.result;
      })
      .catch((e) => {
        toastr.error(e);
      });
    },
    clearMessages: function (id_contato) {
      if(confirm('Deseja realmente apagar a conversa?'))
      {
        chat
          .clearMessages({ id_contato: id_contato })
          .then(() => {
            this.getMessages();
          })
          .catch((e) => {
            toastr.error(e);
          });
      }
    },
    getDadosContato: function() {
      $('#contactInfo').modal('show');
    },
    contactFoward: function() {
      $('#contactFoward').modal('show');
      this.contatoEdit = this.orgData = Object.assign({}, this.contatoInfo);
    },
    openFile: function() {
      $('#fileModal').modal('show');
    },
    sendFoward: function() {
      if(confirm('Deseja realmente Encaminhar este Contato?'))
      {
        contato
          .update(this.contatoEdit)
          .catch((e) => {
            toastr.error(e);
          });
        
        $('#contactFoward').modal('hide');
      }
    },
    sendFile: function() {
      if (!this.canSend()) return;

      let dataForm = new FormData();
      dataForm.append(`id_contato`, this.$route.params.id_contato);
      dataForm.append(`file`, this.$refs.file.files[0]);

      chat.sendFile(dataForm)
        .then((response) => {
          if (!response.data.success)
          {
            toastr.error(response.data.error)
            return;
          }
          
          this.getMessages();
        })
        .catch((e) => {
          toastr.error(e);
        });

      $('#fileModal').modal('hide');
    },
    toogleAtendimento: function (id_contato) {
      if(confirm('Deseja realmente mudar o Atendimento?'))
      {
        contato
          .toogleAtendimento({ id_contato: id_contato })
          .catch((e) => {
            toastr.error(e);
          });
      }
    },
    setWebhook: function () {
      chat
        .setWebhook()
        .catch((e) => {
          toastr.error(e);
        });
    },
    getPesquisa: function (id_contato) {
      if (!this.canSend()) return;
      
      if(confirm('Deseja realmente enviar a Pesquisa?'))
      {
        contato
          .getPesquisa({ id_contato: id_contato })
          .catch((e) => {
            toastr.error(e);
          });
      }
    },
    sendTemplate: function (id_contato) {
     
      if(confirm('Deseja realmente enviar o Template?'))
      {
        contato
          .sendTemplate({ id_contato: id_contato })
          .catch((e) => {
            toastr.error(e);
          });
      }
    }
  },
  created: function () {
    this.getContatos();
    setInterval(this.getContatos, 10000);

    this.getMessages();
    setInterval(this.getMessages, 5000);

    this.getPerfilAtendimentos();
  },
  mounted(){
    usuario.getCurrent()
    .then(response => {
      if(!response.data.success)
        return;

      this.authUser =  response.data.result;
    });

    $('body').popover({
      trigger: 'hover',
      selector: '[rel=tooltip]'
    });
  }
};
</script>
<style scoped>
.container {
  height: 96vh;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
}
.message{
  background-image: url(https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png);
  background-size: 60%;
}

.contact-status {
    position: absolute;
    left: 0;
    margin: -2px 0 0 -2px;
    width: 10px;
    height: 10px !important;
    border-radius: 50%;
    border: 1px solid #2c3e50;
    background: #95a5a6;
}

.opaco{
  opacity: 0.5;
}

.perfilA{
  border-bottom: 2px solid red;
}
.perfilR{
  border-bottom: 2px solid rgba(246, 246, 28, 0.857);
}
.perfilV{
  border-bottom: 2px solid rgb(0, 255, 8);
}
.perfilP{
  border-bottom: 2px solid rgb(0, 132, 255);
}
.perfilS{
  border-bottom: 2px solid rgb(247, 0, 255);
}
.perfilM{
  border-bottom: 2px solid rgb(0, 204, 255);
}
.perfilC{
  border-bottom: 2px solid rgb(0, 8, 255);
}
.perfilF{
  border-bottom: 2px solid rgb(255, 157, 0);
}
.perfilO{
  border-bottom: 2px solid rgb(0, 255, 166);
}
.perfilD{
  border-bottom: 2px solid rgb(0, 1, 2);
}

span.online {
  background: #2ecc71;
}
span.away {
  background: #f1c40f;
}
span.busy {
  background: #e74c3c;
}

.icon-write:before {
  content: "\f00c";
  margin-left: 2px;
}

.icon-send:before {
  content: "\f00c";
  margin-left: 2px;
}

.icon-delivered:before {
  content: "\f560";
  margin-left: 2px;
}

.icon-read:before {
  content: "\f560";
  color: #4FC3F7;
  margin-left: 2px;
}

.icon-failed:before {
  content: "\f06a";
  color: #ec1111;
  margin-left: 2px;
}

.info-box .info-box-icon {
  border-radius: .25rem;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  font-size:25px;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  width:50px;
  padding: 10px;
}

.info-box {
  box-shadow: 0 0 1px rgba(0,0,0,.125),0 1px 3px rgba(0,0,0,.2);
  border-radius: .25rem;
  background-color: rgba(0, 0, 0, 0.04);
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1rem;
  padding: .5rem;
  position: relative;
  width: 320px;
}

.info-box-icon {
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
  display: block;
  float: left;
  width: 90px;
  text-align: center;
  font-size: 45px;
  line-height: 90px;
  background: rgba(0,0,0,0.2);
    background-color: rgba(0, 0, 0, 0.2);
}

.info-box-content {
  padding: 5px 10px;
}

.info-box-text{
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
  max-width: 250px;
  display: block;
}

.message-text a {
  color: black;
  text-decoration: none;
}

.dropdown-toggle::after{
 display: none;
}

.dropleft .dropdown-toggle::before {
  content: '\f142';
  padding-right: 19px;
  margin-right: -28px
}

.pull-right{
  float: right;
}

#app {
background: url("../assets/adminlte/img/default-150x150.png") no-repeat fixed center;
  background-size: cover;
}

.sideBar {
  align-content: start;
}
</style>
