import http from '@/utils/http';

export default {
  async setContato(data) {
    return await http.post('/api/contato/set', data);
  },
  async getContatos(busca) {
    return await http.get('/api/contato/get?busca=' + busca);
  },
  async getContato(id_contato) {
    return await http.get('/api/contato/get/' + id_contato);
  },
  async toogleAtendimento(data) {
    return await http.post('/api/contato/toogle', data);
  },
  async getRelatorio() {
    return await http.get('/api/contato/get/relatorio');
  },
  async getPesquisa(data) {
    return await http.post('/api/contato/pesquisa', data);
  },
  async update(data) {
    return await http.post('/api/contato/update', data);
  },
  async sendTemplate(data) {
    return await http.post('/api/contato/template', data);
  }
};